import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Beer from "../components/BeerImage";
import MainLayout from "../components/MainLayout";
import Seo from "../components/Head";
import Heading from "../components/Heading";
import Salt from "../components/Salt";
import Prefooter from "../components/Prefooter";
import Values from "../components/Values";
import { Paragraph } from "../components/Utilities";

import Pirates from "../images/our-values/boozin-crew.jpg";

const OurValues = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allWpPage(filter: { title: { eq: "Our Values" } }) {
            edges {
              node {
                valuesData {
                  heroSalt
                  heroDescription
                  heroTitle
                  metaTitle
                  metaDescription
                  ourValues {
                    description
                    title
                    badge {
                      altText
                      sourceUrl
                    }
                    bullets {
                      bullet
                    }
                  }
                }
                template {
                  ... on WpDefaultTemplate {
                    footerContent {
                      footerTitle
                      footerDescription
                      footerBackground {
                        altText
                        sourceUrl
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(props) => (
        <MainLayout>
          <Seo
            meta_title={props.allWpPage.edges[0].node.valuesData.metaTitle}
            meta_title_backup={
              props.allWpPage.edges[0].node.valuesData.heroTitle
            }
            meta_description={
              props.allWpPage.edges[0].node.valuesData.metaDescription
            }
          />
          <section className="lg:pt-[65px] max-w-screen-2xl mx-auto" id="hero">
            <div className="grid md:grid-cols-6 items-start md:pl-28">
              <div className="relative z-30 bg-iron px-8 pt-32 pb-20 lg:pt-24 lg:px-24 col-span-4">
                <Heading
                  text={props.allWpPage.edges[0].node.valuesData.heroTitle}
                  alignment={"justify-start"}
                />
                <Paragraph
                  content={
                    props.allWpPage.edges[0].node.valuesData.heroDescription
                  }
                  classes={"text-smoke mt-7"}
                />
                <Beer />
              </div>
              <img
                src={Pirates}
                alt="Pirates drinking beer"
                className="col-span-2 translate-y-20 hidden md:block"
              />
            </div>
            <div className="grid grid-cols-6 items-start -translate-y-24 relative z-20 overflow-hidden lg:overflow-visible pb-28 md:pb-0">
              <StaticImage
                src="../images/our-values/bummer-high.jpg"
                alt="Surfer on beach"
                className="col-span-6 sm:col-span-4 lg:col-span-2 translate-y-20 md:-translate-y-32 lg:-translate-y-52 opacity-20 order-3 md:order-1"
              />
              <div className="col-span-2 relative order-2 z-10 md:z-0">
                <StaticImage
                  src="../images/our-values/bottles.jpg"
                  alt="Bottles of beer"
                  className="absolute w-28 sm:w-40 top-28 md:-top-5 right-64 sm:right-[400px] md:right-0 md:left-0"
                />
                <StaticImage
                  src="../images/our-values/fishing.jpg"
                  alt="People on cliff fishing"
                  className="w-40 md:w-72 lg:w-[325px] -translate-x-[250px] sm:-translate-x-[400px] md:-translate-x-[165px] translate-y-[100px] sm:translate-y-60 md:-translate-y-[25px]"
                />
              </div>
              <StaticImage
                src="../images/our-values/eagle-harbor.jpg"
                alt={"Boat inside of building"}
                className="col-span-4 lg:col-span-2 translate-x-40 sm:translate-x-60 md:-translate-x-[150px] lg:-translate-x-[300px] translate-y-28 md:translate-y-0 order-1 md:order-3 z-20 md:z-0"
              />
              <div className="opacity-0 sm:opacity-100 absolute z-30 bottom-[375px] sm:bottom-[750px] md:bottom-[150px] right-16 md:right-52">
                <Salt
                  title={props.allWpPage.edges[0].node.valuesData.heroSalt}
                  range={[0, 0.25]}
                />
              </div>
            </div>
          </section>
          <Values values={props.allWpPage.edges[0].node.valuesData.ourValues} />
          <Prefooter
            title={
              props.allWpPage.edges[0].node.template.footerContent.footerTitle
            }
            description={
              props.allWpPage.edges[0].node.template.footerContent
                .footerDescription
            }
            background={
              props.allWpPage.edges[0].node.template.footerContent
                .footerBackground
            }
          />
        </MainLayout>
      )}
    />
  );
};
export default OurValues;
