import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { motion, useScroll, useTransform } from "framer-motion";
import loadable from "@loadable/component";

import BeerAnimation from "../images/our-values/beer-animation.gif";

const isBrowser = typeof window !== "undefined";

const BeerComponent = () => {
  const [beerAnimation, setBeerAnimation] = useState("opacity-0");
  const [staticBeer, setStaticBeer] = useState("");
  const [animationTrigger, setAnimationTrigger] = useState("");
  const { scrollYProgress } = useScroll();
  const translateY = useTransform(scrollYProgress, [0, 0.5], [0, -350], {
    clamp: true,
  });

  const showBeerAnimation = () => {
    setStaticBeer("opacity-0");
    setBeerAnimation("opacity-100");
    setAnimationTrigger("pointer-events-none");
    setTimeout(() => {
      setStaticBeer("opacity-100");
      setBeerAnimation("opacity-0");
      setAnimationTrigger("pointer-events-auto");
    }, 5650);
  };

  return (
    <motion.div
      className={`absolute w-32 sm:w-40 md:w-52 right-40 sm:right-72 md:right-44 -bottom-32 sm:-bottom-40 md:-bottom-56 ${animationTrigger}`}
      style={{ translateY: translateY }}
      onClick={showBeerAnimation}
    >
      <StaticImage
        src="../images/our-values/beer.jpg"
        alt="Crushed can of VSSL beer"
        className={`transition-all duration-500 ${staticBeer}`}
      />
      <img
        src={BeerAnimation}
        alt="Animation of VSSL beer can being crushed"
        className={`absolute transition-all duration-500 top-0 left-0 ${beerAnimation}`}
      />
    </motion.div>
  );
};

const LoadableBeer = loadable(() =>
  isBrowser ? Promise.resolve(BeerComponent) : Promise.resolve(() => null)
);

export default function Beer() {
  return (
    <>
      <LoadableBeer />
    </>
  );
}
