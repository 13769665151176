import React from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import loadable from "@loadable/component";
import useIsMobile from "../hooks/useIsMobile";
import useCurseWords from "../hooks/useCurseWords";
import { H3, HtmlParagraph } from "./Utilities";

const isBrowser = typeof window !== "undefined";

const StickerComponent = (props) => {
  const isMobile = useIsMobile(1100);
  useCurseWords();

  const { scrollYProgress } = useScroll();
  const translateX = useTransform(
    scrollYProgress,
    props.primaryCoordinates,
    [25, 0],
    {
      clamp: true,
    }
  );
  const translateY = useTransform(
    scrollYProgress,
    props.primaryCoordinates,
    [25, 0],
    {
      clamp: true,
    }
  );
  const rotate = useTransform(
    scrollYProgress,
    props.primaryCoordinates,
    [-90, 0],
    {
      clamp: true,
    }
  );
  const scale = useTransform(scrollYProgress, props.scale, [1.75, 1], {
    clamp: true,
  });
  const opacity = useTransform(
    scrollYProgress,
    props.primaryCoordinates,
    [0, 1],
    {
      clamp: true,
    }
  );

  return (
    <>
      {isMobile ? (
        <img
          src={props.src}
          alt={props.alt}
          className="max-h-[400px] mx-auto mb-5 mt-20"
        />
      ) : (
        <motion.img
          style={{
            opacity: opacity,
            translateX: translateX,
            translateY: translateY,
            rotate: rotate,
            scale: scale,
          }}
          src={props.src}
          alt={props.alt}
          className="max-h-[400px] max-w-[400px] mx-auto"
        />
      )}
    </>
  );
};

const LoadableSticker = loadable(() =>
  isBrowser ? Promise.resolve(StickerComponent) : Promise.resolve(() => null)
);

export default function Values(props) {
  return (
    <section className="max-w-screen-xl mx-auto px-8 2xl:px-0 -translate-y-32 sm:translate-y-0">
      {props.values.map((value, index) => {
        const coordinates = [
          {
            primaryCoordinates: [0.1, 0.2],
            scale: [0.1, 0.2],
          },
          {
            primaryCoordinates: [0.25, 0.35],
            scale: [0.2, 0.35],
          },
          {
            primaryCoordinates: [0.4, 0.5],
            scale: [0.3, 0.5],
          },
          {
            primaryCoordinates: [0.6, 0.7],
            scale: [0.5, 0.7],
          },
          {
            primaryCoordinates: [0.7, 0.8],
            scale: [0.6, 0.8],
          },
        ];
        return (
          <div
            key={value.title}
            className="grid lg:grid-cols-2 items-start lg:mb-32 xl:mb-52"
          >
            <LoadableSticker
              scale={coordinates[index].scale}
              primaryCoordinates={coordinates[index].primaryCoordinates}
              src={value.badge.sourceUrl}
              alt={value.badge.altText}
            />
            <div>
              <H3 title={value.title} classes={"text-smoke"} />
              <HtmlParagraph
                content={value.description}
                classes={"text-smoke"}
              />
              <ul className="list-disc mt-4">
                {value.bullets.map((bullet) => {
                  return (
                    <li
                      key={bullet.bullet}
                      className="text-smoke text-xl font-light elza leading-[30px] tracking-body ml-6 mb-2"
                    >
                      {bullet.bullet}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        );
      })}
    </section>
  );
}
